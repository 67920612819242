// useContactForm.ts
import { useState } from 'react';

const useContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [industry, setIndustry] = useState('');
  const [businessSize, setBusinessSize] = useState('');
  const [currentSystems, setCurrentSystems] = useState('');
  const [specificNeeds, setSpecificNeeds] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await fetch('https://formspree.io/f/manwpzgp', { // Replace YOUR_FORM_ID with your actual Formspree form ID
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          company,
          industry,
          businessSize,
          currentSystems,
          specificNeeds,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Simulate successful form submission
      setOpen(true);

      // Reset the form
      setName('');
      setEmail('');
      setCompany('');
      setIndustry('');
      setBusinessSize('');
      setCurrentSystems('');
      setSpecificNeeds('');
    } catch (error) {
      setError('Failed to send message. Please try again later.');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    name,
    setName,
    email,
    setEmail,
    company,
    setCompany,
    industry,
    setIndustry,
    businessSize,
    setBusinessSize,
    currentSystems,
    setCurrentSystems,
    specificNeeds,
    setSpecificNeeds,
    open,
    error,
    handleSubmit,
    handleClose,
  };
};

export default useContactForm;
