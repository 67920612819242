import React from 'react';
import { Container, Typography, Box, Divider, Link } from '@mui/material';

const APIDocumentation: React.FC = () => {
  return (
    <Box component="section" sx={{ bgcolor: "#171E32", color: "#FFF", py: 8 }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          sx={{ color: "#00FE00", mb: 4, textAlign: "center" }}
        >
          Khronic AI API Documentation
        </Typography>

        <Typography variant="body1" sx={{ mb: 4 }}>
          Welcome to the Khronic AI API documentation. Here you'll find all the
          information you need to integrate our advanced AI solutions into your
          cannabis business platform. Our APIs are designed to provide robust,
          scalable, and secure solutions that can be seamlessly integrated into
          your AI workflows, enhancing your models with cannabis-specific
          insights.
        </Typography>

        <Divider sx={{ my: 4, bgcolor: "#292F45" }} />

        {/* Introduction Section */}
        <Box sx={{ mb: 6 }}>
          <Typography variant="h4" sx={{ color: "#00FE00", mb: 2 }}>
            Getting Started
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            To begin using our APIs, you'll need to obtain an API key. You can
            request an API key by contacting our sales team or signing up on our
            platform. Once you have your key, you can start making requests to
            our endpoints.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Base URL: <code>http://localhost:7000/api/</code>
          </Typography>
        </Box>

        {/* API Endpoints Section */}
        <Box sx={{ mb: 6 }}>
          <Typography variant="h4" sx={{ color: "#00FE00", mb: 2 }}>
            API Endpoints
          </Typography>

          {/* Query Strain Information */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ color: "#FFF", mb: 1 }}>
              Query Strain Information
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Endpoint: <code>POST /strains/query</code>
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Description: Retrieve detailed information about a specific
              cannabis strain to enrich your AI models with strain-specific
              data.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Example Request:
            </Typography>
            <Box
              component="pre"
              sx={{ bgcolor: "#292F45", p: 2, borderRadius: 1, whiteSpace: 'pre-wrap' }}
            >
              <code>
                {`curl --location 'http://localhost:7000/api/strains/query' \\\n--header 'Content-Type: application/json' \\\n--data '{\n  "query": "Blue Dream"\n}'`}
              </code>
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Example Response:
            </Typography>
            <Box
              component="pre"
              sx={{ bgcolor: "#292F45", p: 2, borderRadius: 1, whiteSpace: 'pre-wrap' }}
            >
              <code>
                {`{
  "name": "Blue Dream",
  "type": "Hybrid",
  "description": "Blue Dream is a popular hybrid strain that provides a balanced mix of relaxation and euphoria...",
  "effects": ["Relaxed", "Happy", "Uplifted"],
  "flavors": ["Berry", "Sweet", "Earthy"]
}`}
              </code>
            </Box>
          </Box>

          {/* Add a New Strain */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ color: "#FFF", mb: 1 }}>
              Add a New Strain
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Endpoint: <code>POST /strains</code>
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Description: Add a new cannabis strain to the database, allowing
              it to be used in future AI queries and workflows.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Example Request:
            </Typography>
            <Box
              component="pre"
              sx={{ bgcolor: "#292F45", p: 2, borderRadius: 1, whiteSpace: 'pre-wrap' }}
            >
              <code>
                {`curl --location 'http://localhost:7000/api/strains' \\\n--header 'Content-Type: application/json' \\\n--data '{\n  "name": "100-Og",\n  "type": "Hybrid",\n  "description": "$100 OG is a 50/50 hybrid strain that packs a strong punch. The name supposedly refers to both its strength and high price when it first started showing up in Hollywood. As a plant, $100 OG tends to produce large dark green buds with few stems. Users report a strong body effect of an indica for pain relief with the more alert, cerebral feeling thanks to its sativa side."\n}'`}
              </code>
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Example Response:
            </Typography>
            <Box
              component="pre"
              sx={{ bgcolor: "#292F45", p: 2, borderRadius: 1, whiteSpace: 'pre-wrap' }}
            >
              <code>
                {`{
  "success": true,
  "message": "Strain added successfully.",
  "strain": {
    "name": "100-Og",
    "type": "Hybrid",
    "description": "$100 OG is a 50/50 hybrid strain that packs a strong punch..."
  }
}`}
              </code>
            </Box>
          </Box>

          {/* Ask a Strain-Related Question */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ color: "#FFF", mb: 1 }}>
              Strain-Related Queries
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Endpoint: <code>POST /strains/query</code>
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Description: Ask a specific question related to a cannabis strain,
              and receive an AI-generated response. This can be used to retrieve
              data-driven answers to integrate into your existing AI models.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Example Request:
            </Typography>
            <Box
              component="pre"
              sx={{ bgcolor: "#292F45", p: 2, borderRadius: 1, whiteSpace: 'pre-wrap' }}
            >
              <code>
                {`curl --location 'http://localhost:7000/api/strains/query' \\\n--header 'Content-Type: application/json' \\\n--data '{\n  "query": "What are the effects of Blue Dream?"\n}'`}
              </code>
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Example Response:
            </Typography>
            <Box
              component="pre"
              sx={{ bgcolor: "#292F45", p: 2, borderRadius: 1, whiteSpace: 'pre-wrap' }}
            >
              <code>
                {`{
  "query": "What are the effects of Blue Dream?",
  "response": "Blue Dream is known for its balanced effects, offering relaxation without heavy sedation. Common effects include feeling relaxed, happy, and uplifted."
}`}
              </code>
            </Box>
          </Box>

          {/* Chatbot Communication */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ color: "#FFF", mb: 1 }}>
              Chatbot Communication
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Endpoint: <code>POST /chatbot/query</code>
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Description: Communicate with Khronic AI's chatbot to receive
              conversational responses on various topics related to cannabis.
              This can be integrated into your own applications for a more
              interactive user experience.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Example Request:
            </Typography>
            <Box
              component="pre"
              sx={{ bgcolor: "#292F45", p: 2, borderRadius: 1, whiteSpace: 'pre-wrap' }}
            >
              <code>
                {`curl --location 'http://localhost:7000/api/chatbot/query' \\\n--header 'Content-Type: application/json' \\\n--data '{\n  "query": "Tell me about the benefits of CBD."\n}'`}
              </code>
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Example Response:
            </Typography>
            <Box
              component="pre"
              sx={{ bgcolor: "#292F45", p: 2, borderRadius: 1, whiteSpace: 'pre-wrap' }}
            >
              <code>
                {`{
  "query": "Tell me about the benefits of CBD.",
  "response": "CBD, or cannabidiol, is known for its therapeutic properties, including pain relief, anxiety reduction, and anti-inflammatory effects. It is non-psychoactive and widely used in both medical and wellness products."
}`}
              </code>
            </Box>
          </Box>
        </Box>

        {/* Support Section */}
        <Box>
          <Typography variant="h4" sx={{ color: "#00FE00", mb: 2 }}>
            Support
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            If you encounter any issues or have questions about our APIs, please
            reach out to our support team at{" "}
            <Link href="mailto:support@khronicai.com" sx={{ color: "#00FE00" }}>
              support@khronicai.com
            </Link>
            .
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default APIDocumentation;
