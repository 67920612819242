import React from 'react';
import { Container, Grid, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';

const GetStartedSection: React.FC = () => {
  return (
    <Box
      component="section"
      sx={{ position: "relative", py: 12, bgcolor: "#171E32" }}
    >
      <Container>
        <Grid container alignItems="center" spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={5}>
            <Typography variant="h2" sx={{ color: "#00FE00", mb: 2 }}>
              Power Your Business with Khronic AI
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
              Khronic AI empowers cannabis businesses with cutting-edge AI
              solutions designed to optimize operations, enhance customer
              experiences, and drive growth. Our APIs offer seamless integration
              and scalability, tailored to meet the unique needs of the cannabis
              industry.
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
              Leverage our AI capabilities to:
            </Typography>
            <Box component="ul" sx={{ pl: 2, color: "#00FE00", mb: 5 }}>
              <Typography
                component="li"
                variant="body1"
                sx={{ color: "#FFF", mb: 1 }}
              >
                Integrate advanced strain analysis and personalized
                recommendations into your platform.
              </Typography>
              <Typography
                component="li"
                variant="body1"
                sx={{ color: "#FFF", mb: 1 }}
              >
                Provide data-driven medical strain matching to enhance customer
                satisfaction.
              </Typography>
              <Typography component="li" variant="body1" sx={{ color: "#FFF" }}>
                Access real-time market insights to stay ahead of industry
                trends and optimize your business strategies.
              </Typography>
            </Box>
            <Link to="/api-documentation" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  position: "relative",
                  zIndex: 10, // Increase z-index to ensure it's on top
                  bgcolor: "#00FE00",
                  color: "#111",
                  fontWeight: "bold",
                  px: 4,
                  py: 1.5,
                  "&:hover": { bgcolor: "#00CC00" },
                }}
              >
                API Documentation
              </Button>
            </Link>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={7}>
            <Grid container spacing={4}>
              {/* Service 1: Strain Analysis API */}
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src="img/Group 18456.png"
                    alt="Strain Analysis API"
                    sx={{ width: 100, mb: 2 }}
                  />
                  <Typography variant="h6" sx={{ color: "#FFF" }}>
                    Strain Analysis API
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#FFF" }}>
                    Integrate our AI-powered strain analysis into your
                    applications to offer unparalleled insights to your
                    customers.
                  </Typography>
                </Box>
              </Grid>

              {/* Service 2: Medical Matching API */}
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src="img/cells.png"
                    alt="Medical Matching API"
                    sx={{ width: 100, mb: 2 }}
                  />
                  <Typography variant="h6" sx={{ color: "#FFF" }}>
                    Medical Matching API
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#FFF" }}>
                    Deliver personalized medical strain recommendations with our
                    AI-driven matching algorithms.
                  </Typography>
                </Box>
              </Grid>

              {/* Service 3: Market Insights API */}
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src="img/quality-check.png"
                    alt="Market Insights API"
                    sx={{ width: 100, mb: 2 }}
                  />
                  <Typography variant="h6" sx={{ color: "#FFF" }}>
                    Market Insights API
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#FFF" }}>
                    Utilize real-time market analytics to drive business growth
                    and stay competitive.
                  </Typography>
                </Box>
              </Grid>

              {/* Service 4: Custom AI Solutions */}
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src="img/stand.png"
                    alt="Custom AI Solutions"
                    sx={{ width: 100, mb: 2 }}
                  />
                  <Typography variant="h6" sx={{ color: "#FFF" }}>
                    Custom AI Solutions
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#FFF" }}>
                    Work with us to develop tailored AI solutions that meet the
                    unique needs of your cannabis business.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ mt: 8 }}>
          <ContactForm /> {/* Integrate the ContactForm component */}
        </Box>
      </Container>
      <Box
        component="img"
        src="img/get-started-background.png"
        alt="Get Started Background"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "auto",
          opacity: 0.2,
        }}
      />
    </Box>
  );
};

export default GetStartedSection;
