
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Banner from './components/Banner';
import GetStartedSection from './components/GetStartedSection';
import Footer from './components/Footer';
import APIDocumentation from './components/APIDocumentation';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            {/* Home Page Route */}
            <Route path="/" element={
              <>
                <Banner />
                <GetStartedSection />
              </>
            } />
            
            {/* API Documentation Route */}
            <Route path="/api-documentation" element={<APIDocumentation />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
