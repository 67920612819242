import React from 'react';
import { Container, Box, Typography, TextField, Button, Snackbar, Alert, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import useContactForm from '../hooks/useContactForm';

const ContactForm: React.FC = () => {
  const {
    name, setName,
    email, setEmail,
    company, setCompany,
    industry, setIndustry,
    businessSize, setBusinessSize,
    currentSystems, setCurrentSystems,
    specificNeeds, setSpecificNeeds,
    open, handleSubmit, handleClose
  } = useContactForm();

  return (
    <Box
      component="section"
      sx={{
        py: 12,
        backgroundColor: 'transparent',
        position: 'relative',
        zIndex: 10,
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" sx={{ color: "#00FE00", mb: 4, textAlign: 'center' }}>
          Interested in Learning More?
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 6, textAlign: 'center' }}>
          Fill out the form below to get in touch with us and learn more about how Khronic AI can help your cannabis business.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            padding: 4,
            borderRadius: 4,
            boxShadow: 3,
            zIndex: 20,
            position: 'relative',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            sx={{ bgcolor: "#FFF", borderRadius: 2 }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ bgcolor: "#FFF", borderRadius: 2 }}
          />
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
            sx={{ bgcolor: "#FFF", borderRadius: 2 }}
          />
          <FormControl fullWidth variant="outlined" sx={{ bgcolor: "#FFF", borderRadius: 2 }}>
            <InputLabel id="industry-label">Industry</InputLabel>
            <Select
              labelId="industry-label"
              value={industry}
              onChange={(e) => setIndustry(e.target.value as string)}
              label="Industry"
              required
            >
              <MenuItem value="Cannabis">Cannabis</MenuItem>
              <MenuItem value="Technology">Technology</MenuItem>
              <MenuItem value="Retail">Retail</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" sx={{ bgcolor: "#FFF", borderRadius: 2 }}>
            <InputLabel id="business-size-label">Business Size</InputLabel>
            <Select
              labelId="business-size-label"
              value={businessSize}
              onChange={(e) => setBusinessSize(e.target.value as string)}
              label="Business Size"
              required
            >
              <MenuItem value="Small">Small</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Large">Large</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Current Systems in Use"
            variant="outlined"
            fullWidth
            value={currentSystems}
            onChange={(e) => setCurrentSystems(e.target.value)}
            required
            sx={{ bgcolor: "#FFF", borderRadius: 2 }}
          />
          <TextField
            label="Specific Needs or Questions"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={specificNeeds}
            onChange={(e) => setSpecificNeeds(e.target.value)}
            required
            sx={{ bgcolor: "#FFF", borderRadius: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#00FE00",
              color: "#111",
              fontWeight: "bold",
              mt: 2,
              borderRadius: 2,
              padding: '12px 24px',
              '&:hover': { bgcolor: "#00CC00" },
            }}
          >
            Submit
          </Button>
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Your message has been sent successfully!
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default ContactForm;


