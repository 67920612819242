import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const TermsOfService: React.FC = () => {
  const effectiveDate = new Date().toLocaleDateString(); // Get today's date in a readable format

  return (
    <Box
      component="section"
      sx={{
        py: 12,
        backgroundColor: 'transparent',
        position: 'relative',
        zIndex: 10, // Lift the zIndex to ensure it's clickable
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h3" sx={{ color: "#00FE00", mb: 4, textAlign: 'center' }}>
          Terms of Service
        </Typography>
        <Typography variant="h6" sx={{ color: "#FFF", mb: 2 }}>
          <strong>Effective Date:</strong> {effectiveDate}
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          Welcome to Khronic AI. These Terms of Service ("Terms") govern your use of our website <a href="http://www.khronicai.com" style={{ color: "#00FE00" }}>www.khronicai.com</a>, our API, and any related services or products provided by Khronic AI. By accessing or using our website, API, or services, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our website, API, or services.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          1. Use of Our Services
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          You agree to use our services only for lawful purposes and in accordance with these Terms. You must not use our services:
          <ul>
            <li>In any way that violates any applicable local, state, national, or international law or regulation.</li>
            <li>To exploit, harm, or attempt to exploit or harm others, including minors.</li>
            <li>To transmit or procure the sending of any advertising or promotional material without our prior written consent.</li>
            <li>To impersonate or attempt to impersonate Khronic AI, a Khronic AI employee, or any other person or entity.</li>
            <li>In any way that damages or disrupts our services or servers.</li>
          </ul>
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          2. Account Responsibility
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          If you create an account on our website or use our API, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or API credentials.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          3. Intellectual Property
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          All content and materials on our website, API, and services, including but not limited to text, graphics, logos, and software, are the property of Khronic AI or its licensors and are protected by intellectual property laws. You may not use, reproduce, distribute, or create derivative works from any content on our website or API without our express written permission.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          4. API Usage
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          If you are licensed to use our API, you agree to:
          <ul>
            <li>Use the API only in accordance with the terms set forth in your license agreement and these Terms.</li>
            <li>Not attempt to reverse engineer, decompile, or disassemble the API.</li>
            <li>Implement reasonable security measures to protect the API and any data obtained from it.</li>
            <li>Not use the API to create a competing product or service.</li>
            <li>Provide accurate and complete information when using the API and ensure that your use of the API complies with all applicable laws and regulations.</li>
            <li>Notify us immediately if you become aware of any unauthorized use of the API or any breach of security related to the API.</li>
          </ul>
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          To the fullest extent permitted by law, Khronic AI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use our services or API; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from our services or API; or (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services or API.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          6. Indemnification
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          You agree to indemnify, defend, and hold harmless Khronic AI, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of our services, API, or your violation of these Terms.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          7. Termination
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We reserve the right to terminate or suspend your account, API access, and access to our services, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use our services and API will immediately cease.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          8. Governing Law
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in [Your Jurisdiction].
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          9. Changes to These Terms
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We may update these Terms from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review these Terms periodically to stay informed about any updates.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          10. Contact Us
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          If you have any questions or concerns about these Terms, please contact us at:
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          <strong>Khronic AI</strong><br />
          Email: <a href="mailto:contact@khronicai.com" style={{ color: "#00FE00" }}>contact@khronicai.com</a><br />
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsOfService;
