import React from 'react';
import { Container, Grid, Typography, Box, Link, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ position: 'relative', bgcolor: '#191F33', color: '#fff', pt: 6, pb: 0 }}>
      {/* Top Footer Section */}
      <Box sx={{ pb: 4, borderBottom: '1px solid #292F45' }}>
        <Container>
          <Grid container spacing={4} justifyContent="space-between">
            {/* Logo and Description */}
            <Grid item xs={12} md={4}>
              <Box sx={{ mb: 2 }}>
                <img src="img/footer-logo.png" alt="Footer Logo" style={{ width: '160px' }} />
              </Box>
              <Typography variant="body2" sx={{ color: '#FFF' }}>
                Empowering the cannabis industry with AI—unleashing the full potential of data-driven insights and predictions.
              </Typography>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={6} md={2}>
              <Typography variant="body1" sx={{ color: '#FFF', mb: 2 }}><b>Quick Links</b></Typography>
              <Box component="ul" sx={{ listStyle: 'none', m: 0, p: 0 }}>
                <li><Link href="mailto:contact@khronicai.com" sx={{ color: '#FFF', display: 'block', mb: 1 }}>Contact</Link></li>
                <li><Link href="/privacy-policy" sx={{ color: '#FFF', display: 'block', mb: 1 }}>Privacy Policy</Link></li>
                <li><Link href="/terms-of-service" sx={{ color: '#FFF', display: 'block', mb: 1 }}>Terms of Service</Link></li>
              </Box>
            </Grid>

            {/* Social Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="body1" sx={{ color: '#FFF', mb: 2 }}><b>Connect with Us</b></Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <IconButton
                  component={Link}
                  href="https://www.linkedin.com/company/khronicai/"
                  sx={{ color: '#FFF', '&:hover': { color: '#00FE00' } }}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://twitter.com/khronicapp"
                  sx={{ color: '#FFF', '&:hover': { color: '#00FE00' } }}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://github.com/khronicai/moonroc"
                  sx={{ color: '#FFF', '&:hover': { color: '#00FE00' } }}
                >
                  <GitHubIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Bottom Footer Section */}
      <Box sx={{ bgcolor: '#292F45', textAlign: 'center', py: 3, mt: 4 }}>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2" sx={{ color: '#FFF' }}>
              © Khronic A.I. {new Date().getFullYear()}
            </Typography>
            <Box
              component="img"
              src="img/icp-w.png" // Replace with your image path
              alt="Copyright Icon"
              sx={{ width: '200px', height: '20px' }} // Adjust size as needed
            />
          </Box>
        </Container>
      </Box>

      {/* Footer Image */}
      <Box component="img" src="img/footer-img.png" alt="Footer Image" sx={{ position: 'absolute', bottom: 0, right: 0, width: '220px', opacity: 0.3 }} />
    </Box>
  );
};

export default Footer;
