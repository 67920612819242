import React from 'react';
import { Container, Grid } from '@mui/material';

const Header: React.FC = () => {
  return (
    <header id="top-header">
      <Container className="container-custom">
        <Grid container className="header-wrapper" spacing={2} py={5}>
          <Grid item xs={6} md={3} className="header-logo">
            <a href="/">
              <img className="w-100" src="img/logo.png" alt="Khronic AI" />
            </a>
          </Grid>
        </Grid>
      </Container>
      <img className="top-header-background-img" src="img/top-wave.png" alt="Header Background" />
    </header>
  );
};

export default Header;
