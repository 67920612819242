import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  const effectiveDate = new Date().toLocaleDateString(); // Get today's date in a readable format

  return (
    <Box
      component="section"
      sx={{
        py: 12,
        backgroundColor: 'transparent',
        position: 'relative',
        zIndex: 10, // Lift the zIndex to ensure it's clickable
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h3" sx={{ color: "#00FE00", mb: 4, textAlign: 'center' }}>
          Privacy Policy
        </Typography>
        <Typography variant="h6" sx={{ color: "#FFF", mb: 2 }}>
          <strong>Effective Date:</strong> {effectiveDate}
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          Welcome to Khronic AI. At Khronic AI, we are committed to protecting your privacy and ensuring a safe online experience. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="http://www.khronicai.com" style={{ color: "#00FE00" }}>www.khronicai.com</a>, use our services, or engage with our products. By using our website or services, you agree to the terms of this Privacy Policy.
        </Typography>
        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          1. Introduction
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          Welcome to Khronic AI. At Khronic AI, we are committed to protecting your privacy and ensuring a safe online experience. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="http://www.khronicai.com" style={{ color: "#00FE00" }}>www.khronicai.com</a>, use our services, or engage with our products. By using our website or services, you agree to the terms of this Privacy Policy.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          2. Information We Collect
        </Typography>

        <Typography variant="body1" sx={{ color: "#FFF", mb: 2 }}>
          <strong>2.1 Personal Information</strong>
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We may collect personal information that you provide directly to us, including:
          <ul>
            <li>Contact Information: Name, email address, company name.</li>
            <li>Business Information: Industry, company size, current systems in use, and specific needs or questions.</li>
            <li>Account Information: Any other information you provide when creating an account or requesting information.</li>
          </ul>
        </Typography>

        <Typography variant="body1" sx={{ color: "#FFF", mb: 2 }}>
          <strong>2.2 Usage Data</strong>
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We automatically collect certain information about your visit to our website, such as:
          <ul>
            <li>Device Information: IP address, browser type, operating system, device information.</li>
            <li>Usage Information: Pages visited, time spent on pages, referring website, and other usage statistics.</li>
          </ul>
        </Typography>

        <Typography variant="body1" sx={{ color: "#FFF", mb: 2 }}>
          <strong>2.3 Cookies and Tracking Technologies</strong>
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We use cookies and other tracking technologies to enhance your experience on our website. Cookies are small data files stored on your device that help us recognize you and remember your preferences. You can adjust your browser settings to refuse cookies, but this may affect your ability to use certain features of our website.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We use the information we collect for the following purposes:
          <ul>
            <li>To Provide and Improve Our Services: To deliver, maintain, and enhance our AI solutions and other services.</li>
            <li>To Communicate With You: To respond to your inquiries, provide customer support, and send updates about our services.</li>
            <li>To Process Transactions: To manage and process payments and other financial transactions.</li>
            <li>To Personalize Your Experience: To tailor our services and communications to your preferences and needs.</li>
            <li>To Analyze Usage: To understand how users interact with our website and services, and to improve them.</li>
          </ul>
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          4. How We Share Your Information
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
          <ul>
            <li>With Service Providers: We may share information with third-party service providers who assist us in operating our website, processing payments, and delivering services.</li>
            <li>For Legal Reasons: We may disclose information if required by law, to comply with legal processes, or to protect our rights and the rights of others.</li>
            <li>Business Transfers: In the event of a merger, acquisition, or other business transaction, your information may be transferred as part of that transaction.</li>
          </ul>
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          5. Data Security
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          6. Your Rights and Choices
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          You have the following rights regarding your personal information:
          <ul>
            <li>Access and Update: You can access and update your personal information by contacting us.</li>
            <li>Opt-Out: You may opt-out of receiving promotional emails by following the unsubscribe instructions in those emails.</li>
            <li>Delete: You may request the deletion of your personal information, subject to legal and contractual obligations.</li>
          </ul>
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          7. Third-Party Links
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those third parties. We encourage you to review the privacy policies of any third-party sites you visit.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          8. Children’s Privacy
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          Our services are not intended for individuals under the age of 18. We do not knowingly collect or solicit personal information from individuals under 18. If we become aware that we have collected such information, we will take steps to delete it.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          9. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
        </Typography>

        <Typography variant="h6" sx={{ color: "#00FE00", mb: 2 }}>
          10. Contact Us
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", mb: 4 }}>
          <strong>Khronic AI</strong><br />
          Email: <a href="mailto:contact@khronicai.com" style={{ color: "#00FE00" }}>contact@khronicai.com</a><br />
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
